import React, { useState, useEffect, useMemo } from "react";
import Layout from "../../../layouts/default";
import { Field, Form, Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { Table, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import Modal from "react-modal";
import {
  getRMChangeList,
  rmcrCount,
} from "../../../store/slices/rmChangeRequestSlice";
import { checkPermission } from "../../../helpers/common";
import RmChangeRequestServices from "../../../services/rmChangeRequestService";
import "../../../assets/css/table.css";
import CustomStyle from "./../../../styles/customStyle";
const RelationshipManagerChangeRequest = () => {
  const { RMChangeList } = useSelector((state) => state.RMChangeListSlice);
  const { RMCRCount } = useSelector((state) => state.RMChangeListSlice);
  const { permission } = useSelector((state) => state.defaulterSlice);
  console.log("permis: ", permission);
  const [values, setValues] = useState({
    old_rm_mobile: "",
    new_rm_mobile: "",
    plan_id: "",
    customer_mobile: "",
    dateFrom: "",
    dateTo: "",
    status: "",
  });
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [data, setData] = useState([]);
  const [plans, setPlans] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [changePlans, setChangePlans] = useState([]);
  const [selectedRM, setSelectedRM] = useState("");
  const [currentRM, setCurrentRM] = useState("");
  const [selectedUser, setSelectedUser] = useState({});
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("thoisabdiwavesfidads: ", page, "filter: ", filter)
    if (page >= 1) {
      const payload = filter;
      payload.page = page - 1;
      dispatch(getRMChangeList(payload));
    } else {
      const payload = { page: 0 };
      dispatch(getRMChangeList(payload));
    }
  }, [dispatch, page]);

  useEffect(() => {
    dispatch(rmcrCount(filter));
  }, [data]);
  useEffect(() => {
    if (RMChangeList) {
      setData(RMChangeList?.data?.result);
      setPlans(RMChangeList?.data?.plans);
    }
  }, [RMChangeList]);

  const count = useMemo(() => {
    return RMCRCount?.count[0]?.count;
  }, [RMCRCount]);

  const handleSubmitStatus = async (data, page) => {
    data.page = page;
    dispatch(getRMChangeList(data));
  };
  const handleActionButton = async (data) => {
    const { old_rm_id, plan_id, old_rm_name } = data;
    setSelectedUser(data);
    setCurrentRM(old_rm_name);
    const payload = {};
    payload.rm_id = old_rm_id;
    payload.plan_id = plan_id;
    const result = await RmChangeRequestServices.getOtherRMHandlingSamePlans(
      payload
    );
    if (result.data.length > 0) {
      setChangePlans(result.data);
      setIsOpen(true);
    } else {
      toast.error(result.message);
    }
  };

  const assignUserToAnotherRM = async (selectedRM, selectedUser) => {
    const payload = {
      new_rm: selectedRM,
      user_id: selectedUser.user_id,
      plan_id: selectedUser.plan_id,
    };
    const updateRM = await RmChangeRequestServices.updateRM(payload);
    if (updateRM.status === 200) {
      const payload = filter;
      payload.page = page - 1;
      setIsOpen(false);
      dispatch(getRMChangeList(payload));
    }
  };
  const _tableHeader = () => {
    return (
      <thead>
        <tr>
          <th>S.No</th>
          <th>Customer</th>
          <th>Plan</th>
          <th>Requested Date</th>
          <th>Old RM</th>
          <th>New RM</th>
          <th>Reason</th>
          <th>Action Taken By</th>
          <th>Action Date</th>
          <th>Status</th>
        </tr>
      </thead>
    );
  };
  const formatDate = (dateString) => {
    const [datePart, timePart] = dateString.split("T");
    const [year, month, day] = datePart.split("-");
    const [hours, minutes] = timePart.split(":");

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };
  const _itemsList = () => {
    if (!data.length) {
      return (
        <tr>
          <td colSpan="9">No record found!!</td>
        </tr>
      );
    }
    return data.map((item, index) => {
      return (
        <tr key={index + (page - 1) * 10}>
          <td>{index + 1 + (page - 1) * 10}</td>
          <td>
            {item.user_name && (
              <>
                {item.user_name}
                <br />
              </>
            )}
            {item.user_mobile && (
              <>
                {item.user_mobile}
                <br />
              </>
            )}
            {item.user_email && <>{item.user_email}</>}
          </td>

          <td>{`${item.plan_name}`}</td>
          <td>{item.date_modified && formatDate(item.date_modified)}</td>
          <td>
            {item.old_rm_name && (
              <>
                {item.old_rm_name}
                <br />
              </>
            )}
            {item.old_rm_mobile && (
              <>
                {item.old_rm_mobile}
                <br />
              </>
            )}
          </td>
          <td>
            {item.new_rm_name && (
              <>
                {item.new_rm_name}
                <br />
              </>
            )}
            {item.new_rm_mobile ? (
              <>
                {item.new_rm_mobile}
                <br />
              </>
            ) : (
              "To be assigned..."
            )}
          </td>
          <td style={{ minWidth: "300px" }}>
            {item.reason ? item.reason : "-"}
          </td>
          <td>
            {item.agent_name && (
              <>
                {item.agent_name}
                <br />
              </>
            )}
            {item.agent_mobile ? (
              <>
                {item.agent_mobile}
                <br />
              </>
            ) : (
              `${item.status === "CHANGED" ? "Updated by system" : "-"}`
            )}
          </td>
          <td style={{ minWidth: "100px" }}>
            {item.action_date ? formatDate(item.action_date) : "-"}
          </td>
          <td>{item.status}</td>
          {checkPermission(
            permission?.data,
            "Relationship Manager Change Request",
            "add_edit"
          ) === 1 &&
            item.status === "PENDING" && (
              <td>
                <button
                  onClick={() => handleActionButton(item)}
                  style={{
                    padding: "3px 5px",
                    border: "none",
                    borderRadius: "4px",
                    backgroundColor: "#007bff",
                    color: "#fff",
                    fontSize: "10px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    marginTop: "16px",
                  }}
                  onMouseEnter={(e) =>
                    (e.target.style.backgroundColor = "#0056b3")
                  }
                  onMouseLeave={(e) =>
                    (e.target.style.backgroundColor = "#007bff")
                  }
                >
                  ACTION
                </button>
              </td>
            )}
        </tr>
      );
    });
  };
  return (
    <Layout heading="Relationship Manager Change Request">
      <div className="form-container">
        <div>
          <Formik
            onSubmit={(values, { setSubmitting }) => {
              console.log("form values#####", values);
              handleSubmitStatus(values, 0);
              setFilter(values);
              setPage(1);
              setSubmitting(false);
            }}
            initialValues={values}
          >
            {({
              isSubmitting,
              errors,
              touched,
              setFieldValue,
              resetForm,
              setSubmitting,
            }) => (
              <Form>
                <div style={{ display: "flex" }}>
                  <div className="input-filters">
                    <ul>
                      <li>
                        <label>OLD RM Mobile</label>
                        <Field
                          className="input"
                          placeholder="Filter By"
                          name="old_rm_mobile"
                          type="text"
                        />
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <label>NEW RM Mobile</label>
                        <Field
                          className="input"
                          placeholder="Filter By"
                          name="new_rm_mobile"
                          type="text"
                        />
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <label>Plan Name</label>
                        <Field
                          as="select"
                          name="plan_id"
                          className="input"
                          onChange={(e) =>
                            setFieldValue("plan_id", e.target.value)
                          }
                        >
                          <option value="" label="Plan" />
                          {plans &&
                            Object.keys(plans).map((key) => (
                              <option key={plans[key].id} value={plans[key].id}>
                                {plans[key].name}
                              </option>
                            ))}
                        </Field>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <label>Customer Mobile</label>
                        <Field
                          className="input"
                          placeholder="Filter By"
                          name="customer_mobile"
                          type="text"
                        />
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <label>Status</label>
                        <Field
                          as="select"
                          name="status"
                          className="input"
                          onChange={(e) =>
                            setFieldValue("status", e.target.value)
                          }
                        >
                          <option value="" label="Select" />
                          <option value="CHANGED" label="Changed" />
                          <option value="PENDING" label="Pending" />
                        </Field>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <label>Date From</label>
                        <Field
                          name="dateFrom"
                          type="date"
                          className="form-control"
                          size="sm"
                          value={dateFrom}
                          onChange={(e) => {
                            setFieldValue("dateFrom", e.target.value);
                            setDateFrom(e.target.value);
                          }}
                          max={
                            dateTo
                              ? new Date(dateTo).toISOString().split("T")[0]
                              : new Date().toISOString().split("T")[0]
                          }
                        />
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <label>Date To</label>
                        <Field
                          name="dateTo"
                          type="date"
                          className="form-control"
                          size="sm"
                          value={dateTo}
                          onChange={(e) => {
                            setFieldValue("dateTo", e.target.value);
                            setDateTo(e.target.value);
                          }}
                          max={new Date().toISOString().split("T")[0]}
                        />
                      </li>
                    </ul>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      marginLeft: "110px",
                    }}
                  >
                    <button
                      type="submit"
                      className="btn-theme"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Submitting..." : "SUBMIT"}
                    </button>
                    <button
                      className="btn-theme"
                      style={{ marginTop: "10px" }}
                      onClick={() => {
                        resetForm();
                        dispatch(getRMChangeList({ page: 0 }));
                        setDateFrom("");
                        setDateTo("");
                        setPage(1);
                        setFilter({});
                      }}
                      type="button"
                    >
                      RESET
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <span style={{ color: "#08238e" }}>{`TOTAL: ${
        count ? count : "0"
      }`}</span>
      <Table className="custom-table">
        {_tableHeader()}
        <tbody>{_itemsList()}</tbody>
      </Table>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          disabled={page === 1}
          onClick={() => {
            setPage((prev) => Math.max(prev - 1, 1));
          }}
        >
          Previous
        </Button>
        <span style={{ alignContent: "center", margin: "10px" }}>
          {" "}
          Page: {page}{" "}
        </span>
        <Button
          onClick={() => setPage((prev) => prev + 1)}
          disabled={page === Math.ceil(count / 10)}
        >
          Next
        </Button>
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={CustomStyle}
        setAppElement={"addContact"}
        contentLabel="RM Change Modal"
      >
        <>
          <div>
            <button
              className="close"
              onClick={() => setIsOpen(false)}
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                color: "#000",
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                width: 0,
                height: 0,
                marginBottom: "12px",
              }}
            >
              &#10005;
            </button>
          </div>

          <h3 style={{ color: "black", textTransform: "capitalize" }}>
            Assign new RM
          </h3>

          <div className="form-box-mobile">
            <label>Current RM</label>
            <p>{currentRM}</p>
            <select
              className="form-control"
              onChange={(e) => setSelectedRM(e.target.value)}
              defaultValue=""
            >
              <option value="" disabled>
                Select Relationship Manager
              </option>
              {changePlans.map((plan) => (
                <option key={plan.rm_id} value={plan.rm_id}>
                  {plan.rm_name}
                </option>
              ))}
            </select>
          </div>

          <div
            style={{
              display: "flex",
              marginTop: "20px",
              justifyContent: "flex-end",
            }}
          >
            <button
              className="btn btn-small btn-theme"
              disabled={!selectedRM}
              onClick={() => assignUserToAnotherRM(selectedRM, selectedUser)}
            >
              Assign
            </button>
          </div>
        </>
      </Modal>
    </Layout>
  );
};

export default RelationshipManagerChangeRequest;
