import React from "react";
import { Table } from "react-bootstrap";
import { format } from "date-fns";

export const BlockAdditionalContactList = (props) => {
  const { contactList } = props;

  //Heading of the table
  const _tableHeader = () => {
    return (
      <thead>
        <tr>
          <th>S.No</th>
          <th>Type</th>
          <th>Details</th>
          <th>Added by</th>
          <th>Date & Time</th>
        </tr>
      </thead>
    );
  };

  console.log("additionalContanctList@@@@@result", contactList);

  const _list = () => {
    if (contactList?.length < 1) {
      return (
        <tr>
          <td colSpan="6">No record found!!</td>
        </tr>
      );
    }
    return contactList.map((item, index) => {
      return (
        <tr key={item.id}>
          <td>{index + 1}</td>
          <td>{item?.contact_type ? item.contact_type : ""}</td>
          <td>{item?.contact_value ? item?.contact_value : ""}</td>
          <td>
            {item.added_by_name}
            <br />
            {item.added_by_mobile}
          </td>
          <td>
            {item?.date_created
              ? format(new Date(item?.date_created), "dd-MM-yyyy h:mm:ss a")
              : ""}
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <div>
        <Table striped bordered hover>
          {_tableHeader()}
          <tbody>{_list()}</tbody>
        </Table>
      </div>
    </>
  );
};
