import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "../../layouts/default";
import { format } from "date-fns";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "react-bootstrap";
import smartSearchService from "../../services/smartSearch";
import { currencyFormatter } from "../../helpers/common";
import { getDefaulterDetail } from "../../store/slices/smartSearch/defaulterDetail";
import { BlockSettlementHistory } from "./Block/SettlementHistory";

const SmartSearchDefaulterDetail = () => {
  const [loading, setLoading] = useState();
  const { smartSearchDefaulterDetail } = useSelector(
    (state) => state.smartSearchDefaulterDetailSlice
  );
  const { search } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    const payload = {
      search: search,
    };
    console.log("payload@@@@@search", payload);
    dispatch(getDefaulterDetail(payload))
      .then((data) => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [search, dispatch]);
  const documentDownloadHandler = (filePath, fileType) => {
    let fileExtension = filePath.split(".").pop();
    let payload = {
      filename: `${fileType}.${fileExtension}`,
      filepath: filePath,
    };
    smartSearchService.downloadEvidenceFile(payload);
  };
  console.log("defaulter detail@@@@@result", smartSearchDefaulterDetail);
  return (
    <Layout
      heading={`Defaulter Detail (${smartSearchDefaulterDetail?.defaulter?.gst_no})`}
    >
      <Row className="gy-3">
        <Col>
          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">GST no. / PAN No</Col>
            <Col className="bg-secondary p-2 bg-opacity-25 text-dark">
              {smartSearchDefaulterDetail?.defaulter?.gst_no &&
              smartSearchDefaulterDetail?.defaulter?.pan_no
                ? `${smartSearchDefaulterDetail?.defaulter?.gst_no} / ${smartSearchDefaulterDetail?.defaulter?.pan_no}`
                : smartSearchDefaulterDetail?.defaulter?.gst_no
                ? smartSearchDefaulterDetail?.defaulter?.gst_no
                : smartSearchDefaulterDetail?.defaulter?.pan_no
                ? smartSearchDefaulterDetail?.defaulter?.pan_no
                : ""}
            </Col>
          </Row>
          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">Legal name</Col>
            <Col className="bg-secondary p-2 text-dark bg-opacity-25">
              {smartSearchDefaulterDetail?.defaulter?.company_name
                ? smartSearchDefaulterDetail?.defaulter?.company_name
                : "N/A"}
            </Col>
          </Row>
          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">Trade Name</Col>
            <Col className="bg-secondary p-2 text-dark bg-opacity-25">
              {smartSearchDefaulterDetail?.defaulter?.trade_name
                ? smartSearchDefaulterDetail?.defaulter?.trade_name
                : "N/A"}
            </Col>
          </Row>
          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">Due Date</Col>
            <Col className="bg-secondary p-2 text-dark bg-opacity-25">
              {smartSearchDefaulterDetail?.defaulter?.payment_due_date
                ? format(
                    new Date(
                      smartSearchDefaulterDetail?.defaulter?.payment_due_date
                    ),
                    "dd-MM-yyyy"
                  )
                : "N/A"}
            </Col>
          </Row>
          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">Defaulter status</Col>
            <Col className="bg-secondary p-2 text-dark bg-opacity-25">
              {smartSearchDefaulterDetail?.defaulter?.defaulters_status}
            </Col>
          </Row>
          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">Verified Date</Col>
            <Col className="bg-secondary p-2 text-dark bg-opacity-25">
              {smartSearchDefaulterDetail?.defaulter?.verified_date
                ? format(
                    new Date(
                      smartSearchDefaulterDetail?.defaulter?.verified_date
                    ),
                    "dd-MM-yyyy"
                  )
                : "N/A"}
            </Col>
          </Row>

          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">State</Col>
            <Col className="bg-secondary p-2 text-dark bg-opacity-25">
              {smartSearchDefaulterDetail?.defaulter?.state_name
                ? smartSearchDefaulterDetail?.defaulter?.state_name
                : "N/A"}
            </Col>
          </Row>
          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">City</Col>
            <Col className="bg-secondary p-2 text-dark bg-opacity-25">
              {smartSearchDefaulterDetail?.defaulter?.city_name
                ? smartSearchDefaulterDetail?.defaulter?.city_name
                : "N/A"}
            </Col>
          </Row>
        </Col>
        <Col>
          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">Reported Amount</Col>
            <Col className="bg-secondary p-2 text-dark bg-opacity-25">
              {currencyFormatter(
                smartSearchDefaulterDetail?.defaulter?.payment_due_amount
                  ? smartSearchDefaulterDetail?.defaulter?.payment_due_amount
                  : "0"
              )}
            </Col>
          </Row>

          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">Settled Amount</Col>
            <Col className="bg-secondary p-2 text-dark bg-opacity-25">
              {currencyFormatter(
                smartSearchDefaulterDetail?.defaulter?.settlement_amount
                  ? smartSearchDefaulterDetail?.defaulter?.settlement_amount
                  : "0"
              )}
            </Col>
          </Row>
          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">Balance Amount</Col>
            <Col className="bg-secondary p-2 text-dark bg-opacity-25">
              {currencyFormatter(
                smartSearchDefaulterDetail?.defaulter?.balance_amount
                  ? smartSearchDefaulterDetail?.defaulter?.balance_amount
                  : "0"
              )}
            </Col>
          </Row>
          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">Write Off</Col>
            <Col className="bg-secondary p-2 text-dark bg-opacity-25">
              {currencyFormatter(
                smartSearchDefaulterDetail?.defaulter?.writeoff_amount
                  ? smartSearchDefaulterDetail?.defaulter?.writeoff_amount
                  : "0"
              )}
            </Col>
          </Row>
          {smartSearchDefaulterDetail?.document &&
            smartSearchDefaulterDetail?.document.length > 0 && (
              <>
                {smartSearchDefaulterDetail?.document.map((doc) => {
                  return (
                    <Row className="p-1">
                      <Col className="bg-secondary p-2 text-white">
                        {doc?.title}
                      </Col>
                      <Col className="bg-secondary p-2 text-dark bg-opacity-25">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-6"
                          style={{
                            width: "22px",
                            height: "auto",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            documentDownloadHandler(
                              doc?.file,
                              doc?.document_type
                            )
                          }
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                          />
                        </svg>
                      </Col>
                    </Row>
                  );
                })}
              </>
            )}
        </Col>
      </Row>
      {smartSearchDefaulterDetail?.settlements &&
        smartSearchDefaulterDetail?.settlements.length > 0 && (
          <div>
            <h4 className="mt-5">Settlement History</h4>
            <BlockSettlementHistory
              settlementHistory={smartSearchDefaulterDetail?.settlements}
            />
          </div>
        )}
    </Layout>
  );
};

export default SmartSearchDefaulterDetail;
