import { combineReducers } from "redux";
import authSlice from "./auth";
import messageSlice from "./message";
import defaultersSlice from "./defaulters";
import sidebarSlice from "./sidebar";
import defaulterSlice from "./defaulter";
import settlementRequestsSlice from "./settlementRequests";
import paymentSlice from "./payment";
import supplierSlice from "./supplier";
import creditqDefaultersSlice from "./creditqDefaulters/index";
import paymentTypeSlice from "./creditqDefaulters/payments";
import RMChangeListSlice from "./rmChangeRequestSlice";
import SupplierWalletBalanceSlice from "./walletBalance";
import smartSearchSlice from "./smartSearch/smartSearch";
import smartSearchUserDetailSlice from "./smartSearch/userDetail";
import smartSearchBusinessDetailSlice from "./smartSearch/businessDetail";
import smartSearchDefaulterDetailSlice from "./smartSearch/defaulterDetail";
import smartSearchAdditionalContactLogSlice from "./smartSearch/additionalContactLog";

export default combineReducers({
  authSlice,
  messageSlice,
  defaultersSlice,
  sidebarSlice,
  defaulterSlice,
  settlementRequestsSlice,
  paymentSlice,
  supplierSlice,
  creditqDefaultersSlice,
  paymentTypeSlice,
  RMChangeListSlice,
  SupplierWalletBalanceSlice,
  smartSearchSlice,
  smartSearchUserDetailSlice,
  smartSearchBusinessDetailSlice,
  smartSearchDefaulterDetailSlice,
  smartSearchAdditionalContactLogSlice,
});
