import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "react-bootstrap";
import Layout from "../../layouts/default";
import { getUserDetail } from "../../store/slices/smartSearch/userDetail";
import { BlockBusinessList } from "./Block/BusinessList";

const SmartSearchUserDetail = () => {
  const [loading, setLoading] = useState();
  const [numberOfBusiness, setNumberOfBusiness] = useState(0);
  const [walletDetail, setWalletDetail] = useState(null);
  const [businessList, setBusinessList] = useState(null);
  const { smartSearchUserDetail } = useSelector(
    (state) => state.smartSearchUserDetailSlice
  );
  const dispatch = useDispatch();

  const { search } = useParams();
  useEffect(() => {
    const payload = {
      search: search,
    };
    dispatch(getUserDetail(payload))
      .then((data) => {
        if (data?.payload?.data?.business) {
          setNumberOfBusiness(data.payload.data.business.length);
          setBusinessList({ data: data?.payload?.data?.business });
          if (data?.payload?.data?.wallet) {
            setWalletDetail();
          }
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [search, dispatch]);
  console.log("userDetails@@@@@@search", smartSearchUserDetail?.business);
  return (
    <Layout heading="User Detail">
      <Row className="gy-3">
        <Col>
          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">Name</Col>
            <Col className="bg-secondary p-2 bg-opacity-25 text-dark">
              {smartSearchUserDetail?.user?.name}
            </Col>
          </Row>
          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">Mobile</Col>
            <Col className="bg-secondary p-2 text-dark bg-opacity-25">
              {smartSearchUserDetail?.user?.mobile}
            </Col>
          </Row>
          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">Email</Col>
            <Col className="bg-secondary p-2 text-dark bg-opacity-25">
              {smartSearchUserDetail?.user?.email
                ? smartSearchUserDetail?.user?.email
                : "N/A"}
            </Col>
          </Row>
          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">User Plan</Col>
            <Col className="bg-secondary p-2 text-dark bg-opacity-25">
              {smartSearchUserDetail?.user?.planName}
            </Col>
          </Row>
          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">
              Account Created Date
            </Col>
            <Col className="bg-secondary p-2 text-dark bg-opacity-25">
              {smartSearchUserDetail?.user?.date_created
                ? format(
                    new Date(smartSearchUserDetail?.user?.date_created),
                    "dd-MM-yyyy"
                  )
                : "N/A"}
            </Col>
          </Row>
          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">
              Subscription Purchase Date
            </Col>
            <Col className="bg-secondary p-2 text-dark bg-opacity-25">
              {smartSearchUserDetail?.user?.subscription_purchased_date
                ? format(
                    new Date(
                      smartSearchUserDetail?.user?.subscription_purchased_date
                    ),
                    "dd-MM-yyyy"
                  )
                : "N/A"}
            </Col>
          </Row>
          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">
              Number of Business Registered
            </Col>
            <Col className="bg-secondary p-2 text-dark bg-opacity-25">
              {numberOfBusiness}
            </Col>
          </Row>
        </Col>
        <Col>
          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">Plan Expired Date</Col>
            <Col className="bg-secondary p-2 text-dark bg-opacity-25">
              {smartSearchUserDetail?.user?.expiryDate
                ? format(
                    new Date(smartSearchUserDetail?.user?.expiryDate),
                    "dd-MM-yyyy"
                  )
                : "N/A"}
            </Col>
          </Row>
          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">
              Total No of Defaulter Reported
            </Col>
            <Col className="bg-secondary p-2 text-dark bg-opacity-25">
              {smartSearchUserDetail?.user?.total_reported_defaulter}
            </Col>
          </Row>
          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">
              Total No. of Defaulter Settled
            </Col>
            <Col className="bg-secondary p-2 text-dark bg-opacity-25">
              {smartSearchUserDetail?.user?.total_settled_defaulter
                ? smartSearchUserDetail?.user?.total_settled_defaulter
                : 0}
            </Col>
          </Row>
          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">RM Name</Col>
            <Col className="bg-secondary p-2 text-dark bg-opacity-25">
              {smartSearchUserDetail?.user?.rm_name
                ? smartSearchUserDetail?.user?.rm_name
                : "N/A"}
            </Col>
          </Row>
          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">
              Reserved wallet points
            </Col>
            <Col className="bg-secondary p-2 text-dark bg-opacity-25">
              {walletDetail?.reservedPoints ? walletDetail?.reservedPoints : 0}
            </Col>
          </Row>
          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">
              Active Wallet Points
            </Col>
            <Col className="bg-secondary p-2 text-dark bg-opacity-25">
              {walletDetail?.reservedPoints
                ? walletDetail?.totalActiveBalance
                : 0}
            </Col>
          </Row>
          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">
              Inactive Wallet Points
            </Col>
            <Col className="bg-secondary p-2 text-dark bg-opacity-25">
              {walletDetail?.reservedPoints
                ? walletDetail?.totalInActiveBalance
                : 0}
            </Col>
          </Row>
        </Col>
      </Row>
      {smartSearchUserDetail?.business && (
        <>
          <h4 className="mt-3">Businesses</h4>
          <BlockBusinessList businessList={businessList} />
        </>
      )}
    </Layout>
  );
};

export default SmartSearchUserDetail;
