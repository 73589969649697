import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "react-bootstrap";
import Modal from "react-modal";
import { currencyFormatter } from "../../helpers/common";
import Layout from "../../layouts/default";
import smartSearchService from "../../services/smartSearch";
import { getBusinessDetail } from "../../store/slices/smartSearch/businessDetail";
import { getAdditionalContactLog } from "../../store/slices/smartSearch/additionalContactLog";
import { BlockDefaulterList } from "./Block/DefaulterList";
import { BlockDirectorList } from "./Block/DirectorList";
import { BlockAdditionalContactList } from "./Block/AdditionalContactList";
import { BlockAdditionalContactLog } from "./Block/AdditionalContactLog";
import CustomStyle from "./../../styles/customStyle";

const SmartSearchBusinessDetail = () => {
  const [loading, setLoading] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [defaulterList, setDefaulterList] = useState(null);
  const [directorList, setDirectorList] = useState(null);
  const [additionalContactList, setAdditionalContactList] = useState(null);
  const [numberOfDefaulter, setNumberOfDefaulter] = useState(0);
  const { smartSearchBusinessDetail } = useSelector(
    (state) => state.smartSearchBusinessDetailSlice
  );
  const { smartSearchAdditionalContactLog } = useSelector(
    (state) => state.smartSearchAdditionalContactLogSlice
  );
  const dispatch = useDispatch();

  const { search } = useParams();
  useEffect(() => {
    const payload = {
      search: search,
    };
    dispatch(getBusinessDetail(payload))
      .then((data) => {
        if (data?.payload?.data?.defaulter) {
          setDefaulterList({ data: data?.payload?.data?.defaulter });
          setDirectorList(data?.payload?.data?.director);
          setAdditionalContactList(data?.payload?.data?.additionalContact);
          if (data?.payload?.data?.defaulter) {
            setNumberOfDefaulter(data?.payload?.data?.defaulter.length);
          }
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [search, dispatch]);

  const additionalContactLogHandler = () => {
    const payload = {
      gstNo: smartSearchBusinessDetail?.business?.gst_no,
    };
    dispatch(getAdditionalContactLog(payload))
      .then(() => {
        setLoading(false);
        setIsOpen(true);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const documentDownloadHandler = (filePath, agreementStatus) => {
    // let fileExtension = filePath.split(".").pop();
    let payload = {
      filename: `payment-proof`,
      filepath: filePath,
      bucketType:
        agreementStatus === "Service Agreement" ? "agreement" : "consent",
    };
    console.log("payment-proof-download@@@@payload", payload);
    smartSearchService.downloadEvidenceFile(payload);
  };

  console.log("businessDetails@@@@@@search", smartSearchBusinessDetail, search);
  return (
    <Layout
      heading={`Business Detail (${smartSearchBusinessDetail?.business?.gst_no})`}
    >
      <Row className="gy-3">
        <Col>
          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">Login Mobile</Col>
            <Col className="bg-secondary p-2 bg-opacity-25 text-dark">
              {smartSearchBusinessDetail?.business?.mobile
                ? smartSearchBusinessDetail?.business?.mobile
                : "N/A"}
            </Col>
          </Row>
          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">
              GST No / PAN number
            </Col>
            <Col className="bg-secondary p-2 text-dark bg-opacity-25">
              {smartSearchBusinessDetail?.business?.gst_no}
              {smartSearchBusinessDetail?.business?.pan_no
                ? " / " + smartSearchBusinessDetail?.business?.pan_no
                : "N/A"}
            </Col>
          </Row>
          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">Primary Mobile</Col>
            <Col className="bg-secondary p-2 text-dark bg-opacity-25">
              {smartSearchBusinessDetail?.business?.primary_mobile
                ? smartSearchBusinessDetail?.business?.primary_mobile
                : "N/A"}
            </Col>
          </Row>
          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">Secondary Mobile</Col>
            <Col className="bg-secondary p-2 text-dark bg-opacity-25">
              {smartSearchBusinessDetail?.business?.mobile_secondary
                ? smartSearchBusinessDetail?.business?.mobile_secondary
                : "N/A"}
            </Col>
          </Row>
          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">Primary Email</Col>
            <Col className="bg-secondary p-2 text-dark bg-opacity-25">
              {smartSearchBusinessDetail?.business?.email_primary
                ? smartSearchBusinessDetail?.business?.email_primary
                : "N/A"}
            </Col>
          </Row>
          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">Secondary Email</Col>
            <Col className="bg-secondary p-2 text-dark bg-opacity-25">
              {smartSearchBusinessDetail?.business?.email_secondary
                ? smartSearchBusinessDetail?.business?.email_secondary
                : "N/A"}
            </Col>
          </Row>
          {smartSearchBusinessDetail?.business?.cin_number && (
            <Row className="p-1">
              <Col className="bg-secondary p-2 text-white">CIN Number</Col>
              <Col className="bg-secondary p-2 text-dark bg-opacity-25">
                {smartSearchBusinessDetail?.business?.cin_number}
              </Col>
            </Row>
          )}
          {smartSearchBusinessDetail?.business?.trade_name && (
            <Row className="p-1">
              <Col className="bg-secondary p-2 text-white">Trade Name</Col>
              <Col className="bg-secondary p-2 text-dark bg-opacity-25">
                {smartSearchBusinessDetail?.business?.trade_name}
              </Col>
            </Row>
          )}
          {smartSearchBusinessDetail?.business?.company_name && (
            <Row className="p-1">
              <Col className="bg-secondary p-2 text-white">Legal Name</Col>
              <Col className="bg-secondary p-2 text-dark bg-opacity-25">
                {smartSearchBusinessDetail?.business?.company_name}
              </Col>
            </Row>
          )}
          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">State</Col>
            <Col className="bg-secondary p-2 text-dark bg-opacity-25">
              {smartSearchBusinessDetail?.business?.state_name
                ? smartSearchBusinessDetail?.business?.state_name
                : "N/A"}
            </Col>
          </Row>
          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">City</Col>
            <Col className="bg-secondary p-2 text-dark bg-opacity-25">
              {smartSearchBusinessDetail?.business?.city_name
                ? smartSearchBusinessDetail?.business?.city_name
                : "N/A"}
            </Col>
          </Row>
          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">Business Category</Col>
            <Col className="bg-secondary p-2 text-dark bg-opacity-25">
              {smartSearchBusinessDetail?.business?.category_name
                ? smartSearchBusinessDetail?.business?.category_name
                : "N/A"}
            </Col>
          </Row>
          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">Business Type</Col>
            <Col className="bg-secondary p-2 text-dark bg-opacity-25">
              {smartSearchBusinessDetail?.business?.company_type
                ? smartSearchBusinessDetail?.business?.company_type
                : "N/A"}
            </Col>
          </Row>
        </Col>
        <Col>
          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">
              Subscription Expiry Date
            </Col>
            <Col className="bg-secondary p-2 text-dark bg-opacity-25">
              {smartSearchBusinessDetail?.business?.date_created
                ? format(
                    new Date(smartSearchBusinessDetail?.business?.date_created),
                    "dd-MM-yyyy"
                  )
                : "N/A"}
            </Col>
          </Row>
          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">
              Agreement Sign Status
            </Col>
            <Col className="bg-secondary p-2 text-dark bg-opacity-25">
              {smartSearchBusinessDetail?.business?.agreement_status}
            </Col>
          </Row>
          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">
              Agreement Sign Date
            </Col>
            <Col className="bg-secondary p-2 text-dark bg-opacity-25">
              {smartSearchBusinessDetail?.business?.signed_date
                ? format(
                    new Date(smartSearchBusinessDetail?.business?.signed_date),
                    "dd-MM-yyyy"
                  )
                : smartSearchBusinessDetail?.business?.agreement_date
                ? format(
                    new Date(
                      smartSearchBusinessDetail?.business?.agreement_date
                    ),
                    "dd-MM-yyyy"
                  )
                : "N/A"}
            </Col>
          </Row>
          {(smartSearchBusinessDetail?.business?.agreement_document_filepath ||
            smartSearchBusinessDetail?.business?.consent_document_filepath) && (
            <Row className="p-1">
              <Col className="bg-secondary p-2 text-white">Agreement</Col>
              <Col className="bg-secondary p-2 text-dark bg-opacity-25">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6 cursor-pointer"
                  style={{ width: "25px", height: "auto", cursor: "pointer" }}
                  onClick={() =>
                    documentDownloadHandler(
                      smartSearchBusinessDetail?.business
                        ?.agreement_document_filepath
                        ? smartSearchBusinessDetail?.business
                            ?.agreement_document_filepath
                        : smartSearchBusinessDetail?.business
                            ?.consent_document_filepath,
                      smartSearchBusinessDetail?.business?.agreement_status
                    )
                  }
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
              </Col>
            </Row>
          )}

          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">No. of Defaulters</Col>
            <Col className="bg-secondary p-2 text-dark bg-opacity-25">
              {numberOfDefaulter}
            </Col>
          </Row>
          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">
              Defaulter’s Cashback
            </Col>
            <Col className="bg-secondary p-2 text-dark bg-opacity-25">
              <div>
                Paid:{" "}
                {currencyFormatter(
                  smartSearchBusinessDetail?.business?.total_paid_amount
                )}
              </div>
              <div>
                Pending:{" "}
                {currencyFormatter(
                  smartSearchBusinessDetail?.business?.total_due_amount
                )}
              </div>
            </Col>
          </Row>
          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">Referral Cashback</Col>
            <Col className="bg-secondary p-2 text-dark bg-opacity-25">
              {currencyFormatter(
                smartSearchBusinessDetail?.business?.total_transaction_amount.toString()
              )}
            </Col>
          </Row>
          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">BCM Sync</Col>
            <Col className="bg-secondary p-2 text-dark bg-opacity-25">
              {smartSearchBusinessDetail?.business?.sync_status}
              {smartSearchBusinessDetail?.business?.sync_date
                ? ", " +
                  format(
                    new Date(smartSearchBusinessDetail?.business?.sync_date),
                    "dd-MM-yyyy"
                  )
                : ""}
            </Col>
          </Row>
          <Row className="p-1">
            <Col className="bg-secondary p-2 text-white">
              No. of CIR Generated
            </Col>
            <Col className="bg-secondary p-2 text-dark bg-opacity-25">
              {smartSearchBusinessDetail?.business?.totalCir
                ? smartSearchBusinessDetail?.business?.totalCir
                : 0}
            </Col>
          </Row>
        </Col>
      </Row>
      {directorList && (
        <>
          <h4 className="mt-5">Directors</h4>
          <BlockDirectorList directorList={directorList} />
        </>
      )}
      {additionalContactList && (
        <>
          <h4 className="mt-5">
            Additional Contacts{" "}
            {additionalContactList.length > 0 && (
              <span
                style={{ cursor: "pointer" }}
                onClick={additionalContactLogHandler}
                className="text-primary text-decoration-underline cursor-pointer"
              >
                Change Log
              </span>
            )}
          </h4>
          <BlockAdditionalContactList contactList={additionalContactList} />
        </>
      )}
      {smartSearchBusinessDetail?.defaulter && (
        <>
          <h4 className="mt-5">Defaulters</h4>
          <BlockDefaulterList defaulterList={defaulterList} />
        </>
      )}

      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={CustomStyle}
        setAppElement={"contactLogs"}
        contentLabel="Additional Contact Logs"
      >
        <h2>Contact Change Log</h2>
        <button
          className="close"
          onClick={closeModal}
          style={{
            fontSize: "20px",
            background: "red",
            borderRadius: "5px",
            color: "black",
            width: "40px",
          }}
        >
          X
        </button>
        <BlockAdditionalContactLog
          contactList={smartSearchAdditionalContactLog}
          closeModal={closeModal}
        />
      </Modal>
    </Layout>
  );
};

export default SmartSearchBusinessDetail;
