import React, { useEffect, useState } from "react";
import Layout from "../../layouts/default";
import { clearMessage } from "../../store/slices/message";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getPermission } from "../../store/slices/defaulter";
import { getDefaulter } from "../../store/slices/creditqDefaulters/index";
import { getCQSupplier } from "../../store/slices/supplier";
import { currencyFormatter } from "../../helpers/common";
import { Col, Row, Card, Button } from "react-bootstrap";
import DefaulterContacts from "../../components/Defaulter/Contacts";
import { format } from "date-fns";
import SettlementPaymentType from "../../components/SettlementPayment/SettlementPaymentType";
import { useNavigate } from "react-router-dom";
import { createPaymentActions } from "../../store/slices/payment";
import SettlementPaymentHistory from "../../components/SettlementPaymentHistory";
import { getPayment } from "../../store/slices/creditqDefaulters/payments";
import { getCallHoldRequest } from "../../store/slices/payment";
import { dateDifference } from "../../services/Misc";
import CallRequestHold from "../../components/CallRequestHold/initaiteRequest";
import { CallRequestHoldList } from "../../components/CallRequestHold/List";
import { getToken, checkPermission } from "../../helpers/common";
import defaultersService from "../../services/payments/";
import { supplierWalletBalance } from "../../store/slices/walletBalance";
import { toast } from "react-toastify";

const CreditqDefaulter = () => {
  let defaulterData = null;

  const { message } = useSelector((state) => state.messageSlice);
  const { creditqDefaulter } = useSelector(
    (state) => state.creditqDefaultersSlice
  );
  const { wallet_balance } = useSelector(
    (state) => state.SupplierWalletBalanceSlice
  );
  const { cqSupplier } = useSelector((state) => state.supplierSlice);
  const { paymentDetails } = useSelector((state) => state.paymentTypeSlice);
  const { permission } = useSelector((state) => state.defaulterSlice);
  const { callRequests } = useSelector((state) => state.paymentSlice);
  const [refresh, setRefresh] = useState(false);
  const [permissionToCreate, setPermissionToCreate] = useState(false);
  const [createPermission, setCreatePermission] = useState(false);
  const [downloadMsg, setDownloadMsg] = useState("");
  const [paymentData, setPaymentData] = useState("");

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // dispatch(clearMessage());
    const fetchDefaulter = async (id) => {
      if (id > 0) {
        let filterData = { id: Number(id) };
        dispatch(getDefaulter({ filterData }));
        dispatch(getPermission());
      }
    };
    fetchDefaulter(id);
  }, [dispatch, id, refresh]);
  useEffect(() => {
    const fetchSupplier = async (id) => {
      if (id > 0) {
        let filterData = { id: Number(id) };
        dispatch(getCQSupplier({ filterData }));
      }
    };

    fetchSupplier(id);
  }, [dispatch, id, refresh]);

  useEffect(() => {
    dispatch(getPayment(id));
  }, [dispatch, id]);

  useEffect(() => {
    let payload = {
      query: {
        defaulter_case_id: creditqDefaulter?.data?.defaulter?.defaulter_id,
      },
    };

    if (creditqDefaulter?.data?.defaulter?.defaulter_id) {
      dispatch(getCallHoldRequest(payload));
    }
  }, [dispatch, creditqDefaulter, refresh]);
  useEffect(() => {
    if (paymentDetails && paymentDetails.data)
      setPaymentData(paymentDetails?.data);
  }, [paymentDetails]);

  if (creditqDefaulter) {
    if (creditqDefaulter.data) defaulterData = creditqDefaulter.data.defaulter;
  }

  useEffect(() => {
    if (defaulterData) {
      const filterData = {
        supplier_user_id: defaulterData?.supplier_user_id,
        active_balance: true,
        inactive_balance: true,
      };
      dispatch(supplierWalletBalance(filterData));
    }
  }, [dispatch, defaulterData]);

  const paymentTypeHandler = (paymentType) => {
    console.log("paid to change - ", paymentType);
    //setShowPayModal(true)
    navigate(`/creditq/defaulter/payment/${paymentType}/${id}/create`);
  };

  const callRequestHoldHandler = (holdType) => {
    try {
      console.log("holdType to change - ", holdType);
      navigate(`/callHold/${holdType}/${id}/create/creditqDefaulter`);
    } catch (error) {
      console.log("Error in callRequestHoldHandler", error);
    }
  };
  const downloadHandlerAgreement = () => {
    let fileExtension;
    let filenameAgreement;
    let token = getToken();
    if (cqSupplier && cqSupplier.data) {
      let agreementDocPath = cqSupplier?.data[0]?.agreement_document;
      console.log("checking match----agreementDocPath", agreementDocPath);
      if (agreementDocPath) {
        filenameAgreement = agreementDocPath;
        console.log(filenameAgreement);
      }
    }
    fileExtension = `agreement.pdf`;
    let payload = {
      filename: fileExtension,
      filepath: filenameAgreement,
      source: "creditq-defaulter",
    };
    console.log("download payload########", payload);
    defaultersService.downloadAgreement(payload, token);
    setDownloadMsg("Agreement Download successfully!");
  };
  const downloadHandlerLedger = () => {
    let token = getToken();
    let filePath;
    if (cqSupplier && cqSupplier.data) {
      filePath = cqSupplier?.data[0]?.latest_ledger_path;
    }

    let postJson = { filename: "ledger.pdf", fileName: filePath };
    defaultersService.downloadLedger(postJson, token);
    setDownloadMsg("Ledger Download successfully!");
  };

  const dateToBeExpired = () => {
    const inputDate = new Date(
      creditqDefaulter?.data?.plan_details?.expiryDate
    ); // Your input date

    const day = inputDate.getDate().toString().padStart(2, "0");
    const month = (inputDate.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based, so add 1
    const year = inputDate.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;
    console.log(formattedDate);
    return formattedDate;
  };
  useEffect(() => {
    console.log("permission is comingcallRequests", permission);
    checkPermissionOfRequestCreate();

    if (permission && permission.data && permission.data.length > 0) {
      permissionSet(permission.data);
    }
  }, [permission, refresh, callRequests]);

  const permissionSet = async (data) => {
    data.forEach((valueSearch) => {
      if (
        valueSearch &&
        valueSearch.title &&
        valueSearch.title === "Call hold create MSME" &&
        valueSearch.add_edit === 1
      ) {
        setPermissionToCreate(true);
      }
    });
  };

  const checkPermissionOfRequestCreate = async () => {
    if (callRequests?.data?.isValidToCreateNewEntry.length === 0) {
      setCreatePermission(true);
    }

    if (
      callRequests?.data?.isValidToCreateNewEntry &&
      callRequests?.data?.isValidToCreateNewEntry[0]?.request_status &&
      callRequests?.data?.isValidToCreateNewEntry[0]?.request_status !=
        "pending" &&
      callRequests?.data?.isValidToCreateNewEntry[0]?.request_status !=
        "paymentApproved" &&
      callRequests?.data?.isValidToCreateNewEntry[0]?.completed_count <= 1
    ) {
      setCreatePermission(true);
    }
  };
  useEffect(() => {
    if (
      callRequests?.data?.isValidToCreateNewEntry[0]?.request_status ===
        "pending" ||
      callRequests?.data?.isValidToCreateNewEntry[0]?.request_status ===
        "paymentApproved"
    ) {
      setCreatePermission(false);
    }
  }, [callRequests]);

  const getDueDays = () => {
    let days = 0;
    if (
      defaulterData?.consent_signed_date &&
      new Date(defaulterData.consent_signed_date) >
        new Date(defaulterData.verified_date)
    ) {
      days = dateDifference(
        defaulterData.payment_due_date,
        defaulterData.consent_signed_date
      );
    } else {
      days = dateDifference(
        defaulterData.payment_due_date,
        defaulterData.verified_date
      );
    }

    return days;
  };

  const subscriptionHighLight = () => {
    let containerStyle = {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      border: "1px solid rgba(0, 0, 0, 0.125)",
      padding: "10px",
      textAlign: "center",
      borderRadius: "20px",
      color: "white",
    };

    if (
      creditqDefaulter.data?.plan_details?.status === "Active" &&
      creditqDefaulter.data?.plan_details?.days_until_expiration > 30
    ) {
      containerStyle.backgroundColor = "green";
    } else if (
      creditqDefaulter.data?.plan_details?.status === "Active" &&
      creditqDefaulter.data?.plan_details?.days_until_expiration <= 30
    ) {
      containerStyle.backgroundColor = "yellow";
    } else {
      containerStyle.backgroundColor = "#F53134"; // Default color
    }

    return containerStyle;
  };
  console.log(
    " wallet_balance?.data?.totalActiveBalance",
    wallet_balance?.data?.totalActiveBalance
  );
console.log("HERE IS ",creditqDefaulter,"AND",cqSupplier)
  return (
    <Layout heading="CreditQ Defaulter">
      {message && <div className="alert alert-success">{message}</div>}

      {defaulterData && (
        <>
          <Row>
            <Col md={6} className="align-items-stretch">
              <Card>
                <Card.Body>
                  <Card.Title>
                    {" "}
                    <strong>DEFAULTER</strong>
                  </Card.Title>

                  <h6>{defaulterData.buyer_trade_name}</h6>
                  <div>{defaulterData.buyer_gstn}</div>
                  <div>{defaulterData.buyer_pan}</div>
                  <div>
                    {defaulterData.buyer_email ? defaulterData.buyer_email : ""}
                  </div>
                  <div>
                    {defaulterData.buyer_mobile
                      ? defaulterData.buyer_mobile
                      : defaulterData.buyer_mobile_secondary}
                  </div>

                  <br />
                  <strong>Extra Details:</strong>
                  <div>
                    {defaulterData.buyer_mobile_secondary
                      ? defaulterData.buyer_mobile_secondary
                      : ""}
                  </div>
                  <div>
                    {defaulterData.defaulters_mobile
                      ? defaulterData.defaulters_mobile
                      : ""}
                  </div>
                  <div>
                    {defaulterData.defaulters_mobile_secondary
                      ? defaulterData.defaulters_mobile_secondary
                      : ""}
                  </div>
                  {creditqDefaulter.data?.contacts ? (
                    creditqDefaulter.data?.contacts.map((item, index) => {
                      if (item.contact_type === "mobile")
                        return <div>{item.contact_value}</div>;
                    })
                  ) : (
                    <></>
                  )}

                  <div>
                    {defaulterData.buyer_email_secondary
                      ? defaulterData.buyer_email_secondary
                      : ""}
                  </div>
                  <div>
                    {defaulterData.defaulters_email
                      ? defaulterData.defaulters_email
                      : ""}
                  </div>
                  <div>
                    {defaulterData.defaulters_email_secondary
                      ? defaulterData.defaulters_email_secondary
                      : ""}
                  </div>
                  {defaulterData?.contacts ? (
                    defaulterData?.contacts.map((item, index) => {
                      if (item.contact_type === "email")
                        return <div>{item.contact_value}</div>;
                    })
                  ) : (
                    <></>
                  )}
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} className="align-items-stretch">
              <Card>
                <Card.Body>
                  <Card.Title>
                    <strong>SUPPLIER</strong>
                  </Card.Title>

                  <h6>{defaulterData.supplier_trade_name}</h6>
                  <div>{defaulterData.supplier_gstn} </div>
                  <div>{defaulterData.supplier_user_email}</div>
                  <div>{defaulterData.supplier_user_mobile}</div>
                  <br />
                  {defaulterData?.supplier_has_lwb === 1 && (
                    <span
                      className="blinking-text"
                      style={{ color: "red", fontWeight: "bold" }}
                    >
                      WARNING: Supplier has low wallet balance{" "}
                    </span>
                  )}
                  <br />

                  <strong>Business contacts:</strong>

                  <div>{defaulterData.supplier_email}</div>
                  <div>{defaulterData.supplier_mobile}</div>

                  <div style={subscriptionHighLight()}>
                    <h5 style={{ color: "white" }}>Subscription details</h5>
                    <span style={{ color: "white" }}>
                      <strong style={{ color: "white" }}>status : </strong>
                      {creditqDefaulter.data?.plan_details?.status}
                    </span>{" "}
                    <span style={{ color: "white" }}>
                      <strong style={{ color: "white" }}> Expiry date :</strong>
                      {dateToBeExpired()}
                    </span>{" "}
                    <span style={{ color: "white" }}>
                      <strong style={{ color: "white" }}>
                        Remaining days:{" "}
                      </strong>
                      {
                        creditqDefaulter.data?.plan_details
                          ?.days_until_expiration
                      }
                    </span>
                    <span style={{ color: "white" }}>
                      <strong style={{ color: "white" }}>
                        Membership Plan:{" "}
                      </strong>
                      {
                        (creditqDefaulter.data?.plan_details
                          ?.plan_name).toUpperCase()
                      }
                    </span>
                    <span style={{ color: "white" }}>
                      <strong style={{ color: "white" }}>
                        Multiple Factor:{" "}
                      </strong>
                      {
                        creditqDefaulter.data?.plan_details
                          ?.wallet_multiplication
                      }
                    </span>
                  <span style={{ color: "white" }}>
                      <strong style={{ color: "white" }}>
                        MSME Signed Date:{" "}
                      </strong>
                      {
                          creditqDefaulter?.data?.msmeSigned_date
                          ?.date_modified ||"Not Signed"
                      }
                    </span>
                    <span style={{ color: "white" }}>
                      <strong style={{ color: "white" }}>
                        Verified Defaulter Count:{" "}
                      </strong>
                      {
                        creditqDefaulter.data?.defaulterCount
                          ?.verified_defaulter_count||0
                      }
                    </span>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <span style={{ color: "white" }}>
                        <strong style={{ color: "white" }}>
                          {" "}
                          Reserved Points :
                        </strong>
                        {wallet_balance && wallet_balance?.data?.reservedPoints}
                      </span>{" "}
                      <span style={{ color: "white" }}>
                        <strong style={{ color: "white" }}>
                          {" "}
                          Active Points :
                        </strong>
                        {Math.max(
                          wallet_balance
                            ? Number(
                                wallet_balance.data?.totalActiveBalance || 0
                              ) -
                                Number(wallet_balance.data?.reservedPoints || 0)
                            : 0,
                          0
                        )}
                      </span>{" "}
                      <span style={{ color: "white" }}>
                        <strong style={{ color: "white" }}>
                          {" "}
                          Inactive Points :
                        </strong>
                        {wallet_balance &&
                          wallet_balance?.data?.totalInActiveBalance}
                      </span>{" "}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={6}>
              <Card>
                <Card.Body>
                  <Card.Title>
                    {" "}
                    <strong>DEFAULTER STATUS</strong>
                  </Card.Title>

                  <br />

                  <table className="table table-striped">
                    <tbody>
                      <tr>
                        <td>Default Amount:</td>
                        <td>
                          {currencyFormatter(defaulterData.payment_due_amount)}
                        </td>
                      </tr>
                      <tr>
                        <td>Paid amount</td>
                        <td>
                          {currencyFormatter(defaulterData.settled_amount)}
                        </td>
                      </tr>

                      <tr>
                        <td>Balance amount</td>
                        <td>
                          {defaulterData.defaulters_status === "full_settled"
                            ? 0
                            : defaulterData.payment_due_amount -
                                defaulterData.settled_amount >
                              0
                            ? currencyFormatter(
                                defaulterData.payment_due_amount -
                                  defaulterData.settled_amount
                              )
                            : 0}
                        </td>
                      </tr>
                      {defaulterData.defaulters_status === "full_settled" &&
                        defaulterData.payment_due_amount -
                          defaulterData.settled_amount >
                          0 && (
                          <tr>
                            <td>Write Off</td>
                            <td>
                              {currencyFormatter(
                                defaulterData.payment_due_amount -
                                  defaulterData.settled_amount
                              )}
                            </td>
                          </tr>
                        )}

                      <tr>
                        <td>Status</td>
                        <td>{defaulterData.defaulters_status}</td>
                      </tr>
                      <tr>
                        <td>Verified date</td>
                        <td>
                          {format(
                            new Date(defaulterData.verified_date),
                            "yyyy-MM-dd"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>UDIN number</td>
                        <td>{defaulterData.udin_no}</td>
                      </tr>
                      <tr>
                        <td>Legal case</td>
                        <td>{defaulterData.legal_case}</td>
                      </tr>
                      <tr>
                        <td>Due date</td>
                        <td>{defaulterData.payment_due_date}</td>
                      </tr>
                      <tr>
                        <td>No. of days</td>
                        <td>{getDueDays()}</td>
                      </tr>
                    </tbody>
                  </table>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Title>
                    {" "}
                    <strong>DEFAULTER DOCUMENTS</strong>
                  </Card.Title>

                  <br />
                  {downloadMsg && (
                    <div className="alert alert-success">{downloadMsg}</div>
                  )}
                  <table className="table table-striped">
                    <tbody>
                      <tr>
                        {/* <td>Ledger</td> */}

                        <td>
                          {" "}
                          <Button
                            variant="outline-secondary"
                            onClick={downloadHandlerLedger}
                          >
                            Download latest ledger
                          </Button>
                        </td>
                        <td>
                          {" "}
                          <Button
                            variant="outline-secondary"
                            onClick={downloadHandlerAgreement}
                          >
                            Download Service Agreement Document
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Card.Body>
              </Card>
              {checkPermission(
                permission?.data,
                "CreditQ Defaulters Settlement",
                "listView"
              ) === 1 &&
                (defaulterData?.defaulters_status === "verified" ||
                  defaulterData?.defaulters_status === "partial_settled") && (
                  <Row>
                    <Col md={12}>
                      <Card className="px-3 py-3">
                        <Card.Title>
                          {" "}
                          <strong>SETTLEMENT PAYMENT TYPE</strong>
                        </Card.Title>
                        <Card.Body>
                          <SettlementPaymentType
                            paymentTypeHandler={paymentTypeHandler}
                            paymentData={paymentData}
                          />
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                )}
              {checkPermission(
                permission?.data,
                "Call hold create MSME",
                "add_edit"
              ) === 1 ? (
                (defaulterData?.defaulters_status === "verified" ||
                  defaulterData?.defaulters_status === "partial_settled") &&
                createPermission ? (
                  <Row style={{ marginTop: "20px" }}>
                    <Col md={12}>
                      <Card className="px-3 py-3">
                        <Card.Title>
                          <strong>CALL REQUEST HOLD</strong>
                        </Card.Title>
                        <Card.Body>
                          <CallRequestHold
                            callRequestType={callRequestHoldHandler}
                            source={"creditqDefaulters"}
                            // buttonDisable={false}
                          />
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                ) : (
                  <Row style={{ marginTop: "20px" }}>
                    <Col md={12}>
                      <Card className="px-3 py-3">
                        <Card.Title>
                          <strong>CALL REQUEST HOLD</strong>
                        </Card.Title>
                        <h6 style={{ marginTop: "10px", color: "red" }}>
                          {`${
                            defaulterData?.defaulters_status === "removed"
                              ? "This Defaulter has been removed!"
                              : defaulterData?.defaulters_status ===
                                "full_settled"
                              ? "This Defaulter is full settled!"
                              : "Already a call hold request is in process..."
                          } `}
                        </h6>
                      </Card>
                    </Col>
                  </Row>
                )
              ) : null}
            </Col>

            <Col md={6}>
              {checkPermission(
                permission?.data,
                "CreditQ Defaulters Settlement History",
                "listView"
              ) === 1 && (
                <Card>
                  <Card.Body>
                    <Card.Title>
                      {" "}
                      <strong>SETTLEMENT PAYMENTS HISTORY</strong>
                    </Card.Title>

                    <div style={{ width: "100%", overflowX: "scroll" }}>
                      {paymentDetails?.data && (
                        <SettlementPaymentHistory
                          payments={paymentDetails}
                          defaulter_id={id}
                        />
                      )}
                      <br />
                    </div>
                  </Card.Body>
                </Card>
              )}
              {permissionToCreate && (
                <Card>
                  <Card.Body>
                    <Card.Title>
                      <strong>CALL HOLD REQUEST HISTORY</strong>
                    </Card.Title>

                    <div style={{ width: "100%", overflowX: "scroll" }}>
                      {paymentDetails?.data && (
                        <CallRequestHoldList
                          callRequests={callRequests}
                          defaulter_id={defaulterData?.defaulter?.defaulter_id}
                          defaulter_case_id={id}
                          source="creditqDefaulters"
                        />
                      )}
                      <br />
                    </div>
                  </Card.Body>
                </Card>
              )}
              <Card>
                <Card.Body>
                  <Card.Title>
                    <strong>SUPPLIER CONTACT DETAILS</strong>
                  </Card.Title>

                  <Row>
                    <Col md={12}>
                      {cqSupplier?.data?.length > 0 ? (
                        <DefaulterContacts
                          supplier_id={defaulterData.supplier_id}
                          defaulterData={cqSupplier.data[0]}
                          setRefresh={setRefresh}
                          refresh={refresh}
                          contactType={"supplier_contact"}
                        />
                      ) : (
                        <div>No data available</div>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </Layout>
  );
};

export default CreditqDefaulter;
