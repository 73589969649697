import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { format } from "date-fns";
import { Button } from "react-bootstrap";
import Layout from "../../layouts/default";
import SmartSearchForm from "./Block/SmartSearchForm";
import {
  getUsers,
  getBusinesses,
  getDefaulters,
} from "../../store/slices/smartSearch/smartSearch";

import { BlockUserList } from "../SmartSearch/Block/UserList";
import { BlockBusinessList } from "../SmartSearch/Block/BusinessList";
import { BlockDefaulterList } from "../SmartSearch/Block/DefaulterList";

export const Home = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  const [loading, setLoading] = useState();
  const [filterData, setFilterData] = useState(null);
  const [pageUser, setPageUser] = useState(1);
  const [pageBusiness, setPageBusiness] = useState(1);
  const [pageDefaulter, setPageDefaulter] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { smartSearch } = useSelector((state) => state.smartSearchSlice);
  const page = queryParams.get("page");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const formSubmitHandler = (payload) => {
    navigate("/app");
    console.log("formValue@@@@@@3", payload);
    setFilterData(payload);
    switch (payload.searchFor) {
      case "user":
        setActiveTab("tab1");
        break;
      case "business":
        setActiveTab("tab2");
        break;
      case "defaulter":
        setActiveTab("tab3");
        break;
      default:
        setActiveTab("tab1");
    }
    if (payload.searchFor === "all" || payload.searchFor === "user") {
      const userPayload = { ...payload, page: pageUser };

      dispatch(getUsers(userPayload))
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
    if (payload.searchFor === "all" || payload.searchFor === "business") {
      setPageBusiness(1);
      const businessPayload = { ...payload, page: pageBusiness };
      dispatch(getBusinesses(businessPayload))
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
    if (payload.searchFor === "all" || payload.searchFor === "defaulter") {
      setPageDefaulter(1);
      const defaulterPayload = { ...payload, page: pageDefaulter };
      dispatch(getDefaulters(defaulterPayload))
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const paginationUserClickHandler = (pageNumber) => {
    const userPayload = { ...filterData, page: pageUser };
    dispatch(getUsers(userPayload))
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const paginationBusinessClickHandler = (pageNumber) => {
    const businessPayload = { ...filterData, page: pageBusiness };
    dispatch(getBusinesses(businessPayload))
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const paginationDefaulterClickHandler = (pageNumber) => {
    const defaulterPayload = { ...filterData, page: pageNumber };
    dispatch(getDefaulters(defaulterPayload))
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  console.log("smartSearch@@@@@", smartSearch);
  const totalUsers = smartSearch?.users?.pagination?.totalPages
    ? smartSearch?.users?.pagination.totalPages
    : 0;
  const totalBusiness = smartSearch?.businesses?.pagination?.totalPages
    ? smartSearch?.businesses?.pagination?.totalPages
    : 0;
  const totalDefaulters = smartSearch?.defaulters?.pagination?.totalPages
    ? smartSearch?.defaulters?.pagination?.totalPages
    : 0;

  return (
    <Layout>
      <SmartSearchForm
        setLoading={setLoading}
        setFilterData={setFilterData}
        onSubmit={formSubmitHandler}
      />
      <div className="tabs" style={{ display: "flex", gap: "10px" }}>
        <Button
          variant="outline-secondary"
          className={activeTab === "tab1" ? "active" : ""}
          onClick={() => handleTabClick("tab1")}
        >
          Users({totalUsers})
        </Button>

        <Button
          variant="outline-secondary"
          className={activeTab === "tab2" ? "active" : ""}
          onClick={() => handleTabClick("tab2")}
        >
          Business({totalBusiness})
        </Button>
        <Button
          variant="outline-secondary"
          className={activeTab === "tab3" ? "active" : ""}
          onClick={() => handleTabClick("tab3")}
        >
          Defaulters({totalDefaulters})
        </Button>
      </div>
      {activeTab === "tab1" && (
        <BlockUserList
          onPaginationClick={paginationUserClickHandler}
          setPage={setPageUser}
        />
      )}
      {activeTab === "tab2" && (
        <BlockBusinessList
          businessList={smartSearch?.businesses}
          onPaginationClick={paginationBusinessClickHandler}
          setPage={setPageBusiness}
        />
      )}
      {activeTab === "tab3" && (
        <BlockDefaulterList
          onPaginationClick={paginationDefaulterClickHandler}
          setPage={setPageDefaulter}
          defaulterList={smartSearch?.defaulters}
        />
      )}
    </Layout>
  );
};
