import React from "react";
import { Table } from "react-bootstrap";

export const BlockDirectorList = (props) => {
  const { directorList } = props;

  //Heading of the table
  const _tableHeader = () => {
    return (
      <thead>
        <tr>
          <th>S.No</th>
          <th>Director Name</th>
          <th>PAN No</th>
          <th>DIN</th>
        </tr>
      </thead>
    );
  };

  console.log("directoryList@@@@@result", directorList);

  const _list = () => {
    if (!directorList) {
      return (
        <tr>
          <td colSpan="4">No record found!!</td>
        </tr>
      );
    }
    return directorList.map((item, index) => {
      return (
        <tr key={item.id}>
          <td>{index + 1}</td>
          <td>{item?.director_name ? item.director_name : ""}</td>
          <td>{item?.pan_number ? item?.pan_number : ""}</td>
          <td>{item.din_number}</td>
        </tr>
      );
    });
  };

  return (
    <>
      <div>
        <Table striped bordered hover>
          {_tableHeader()}
          <tbody>{_list()}</tbody>
        </Table>
      </div>
    </>
  );
};
