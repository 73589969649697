import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../message";
import smartSearchService from "../../../services/smartSearch";

export const getUserDetail = createAsyncThunk(
  "smartSearch/getUserDetail",
  async (payload, thunkAPI) => {
    try {
      console.log("smartSearch/getUserDetail@@@@payload", payload);
      const result = await smartSearchService.getUserDetail(payload);
      if (result.status) {
        return result;
      } else {
        thunkAPI.dispatch(
          setMessage(result?.message ? result?.message : "An error occurred")
        );
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  smartSearchUserDetail: null,
};

const smartSearchUserDetailSlice = createSlice({
  name: "smartSearchUserDetail",
  initialState,
  extraReducers: {
    [getUserDetail.fulfilled]: (state, action) => {
      console.log(
        "smartSearchSlice - @@@@@@getUserDetail-fulfilled",
        action?.payload?.data
      );
      state.smartSearchUserDetail = action.payload.data;
      //return { ...state, defaulters: action.payload.data };
    },
    [getUserDetail.rejected]: (state, action) => {
      console.log(
        "smartSearchSlice - @@@@@@getUserDetail-rejected",
        action?.payload?.data
      );
      state.smartSearchUserDetail = null;
      //return { ...state, defaulters: [] };
    },
  },
});

export default smartSearchUserDetailSlice.reducer;
