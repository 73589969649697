export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const SEND_OTP = API_ENDPOINT + "user/msme/sendotp";
export const VERIFY_OTP = API_ENDPOINT + "user/msme/verifyOtp";
export const LOGOUT = API_ENDPOINT + "auth/logout";
export const GET_DEFAULTERS = API_ENDPOINT + "settlement/defaulters";
export const GET_DEFAULTER = API_ENDPOINT + "settlement/get-defaulter";
export const GET_SUPPLIER = API_ENDPOINT + "settlement/get-supplier";
export const SETTLE_DEFAULTER = API_ENDPOINT + "settlement/settle-defaulter";
export const RESEND_DUE_OFFER_EMAIL_TO_DEFAULTER =
  API_ENDPOINT + "settlement/resend-dueoffer";
export const GET_SETTLEMENT_REQUESTS =
  API_ENDPOINT + "settlement/get-settlement-requests";
export const SETTLE_DEFAULTER_CONTACTS_SAVE =
  API_ENDPOINT + "defaulter/savecontacts";
export const GET_SETTLED_HISTORY = API_ENDPOINT + "defaulter/SettledHistory";

export const GET_PAYMENTS = API_ENDPOINT + "settlement/payments/fetchAll";
export const GET_PAYMENT = API_ENDPOINT + "settlement/payments/";
export const SAVE_PAYMENT = API_ENDPOINT + "settlement/payments/save";
export const UPDATE_PAYMENT = API_ENDPOINT + "settlement/payments/update";
export const UPDATE_CALL_REQUEST =
  API_ENDPOINT + "settlement/callRequest/update";
export const PAYMENT_PROOF_DOWNLOAD =
  API_ENDPOINT + "settlement/payments/download-proof";
export const DOWNLOAD_AGREEMENT =
  API_ENDPOINT + "settlement/agreement/download";
export const DOWNLOAD_LATEST_LEDGER = API_ENDPOINT + "download/defaulterDoc";
export const GET_SETTLEMENT_CASHBACK = API_ENDPOINT + "get/settlementCashback/";
export const SAVE_CALL_HOLD = API_ENDPOINT + "settlement/callhold/save";
export const GET_CALL_HOLD_REQUEST =
  API_ENDPOINT + "settlement/holdCall/fetchAll";
export const GET_CALL_HOLD_REQUEST_BY_ID =
  API_ENDPOINT + "settlement/holdRequest/";
export const GET_ACCESS_PERMISSION =
  API_ENDPOINT + "settlement/holdCall/permission";
export const CHECK_FOR_ONGOING_CALLHOLD =
  API_ENDPOINT + "settlement/checkOnGoingHoldCall";
export const FETCH_DEFAULTER_CONTACTS_FOR_VERIFICATION =
  API_ENDPOINT + "defaulter/contact/verification";
export const UPDATE_DEFAULTER_CONTACTS_VERIFICATION =
  API_ENDPOINT + "update/defaulter/contact/verification";
export const GET_CREDITQ_DEFAULTERS =
  API_ENDPOINT + "get/creditq/defaulters/msme";
export const UPDATE_PAYMENT_FOR_CREDITQ_DEFAULTER =
  API_ENDPOINT + "settlement/update/payment/creditq-defaulter";
export const GET_CREDITQ_DEFAULTER_PAYMENT =
  API_ENDPOINT + "settlement/payments/creditq-defaulter/";
export const PAYMENT_PROOF_DOWNLOAD_CREDITQ_DEFAULTER =
  API_ENDPOINT + "settlement/creditq-defaulter/payment/download-proof";
export const GET_CQ_SUPPLIER = API_ENDPOINT + "settlement/get-cq-supplier";
export const GET_CREDITQ_DEFAULTER =
  API_ENDPOINT + "settlement/get/creditq-defaulter";
export const RM_CHANGE_REQUEST_LIST = API_ENDPOINT + "rm-change/request/list";
export const OTHER_RM_HANDLING_SAME_PLANS =
  API_ENDPOINT + "other/rms-handling-same-plans";
export const UPDATE_RM = API_ENDPOINT + "update/rm/MSME";
export const RMCQ_TOTAL_COUNT =
  API_ENDPOINT + "relationship-manager/changer-request/total-count";
export const GET_SUPPLIER_WALLET_BALANCE =
  API_ENDPOINT + "msme/wallet/balance/";
export const GET_SMART_SEARCH_USERS = API_ENDPOINT + "msme/smartSearch/users";
export const GET_SMART_SEARCH_BUSINESSES =
  API_ENDPOINT + "msme/smartSearch/businesses";
export const GET_SMART_SEARCH_DEFAULTERS =
  API_ENDPOINT + "msme/smartSearch/defaulters";
export const GET_SMART_SEARCH_USER_DETAIL =
  API_ENDPOINT + "msme/smartSearch/userDetail";
export const GET_SMART_SEARCH_BUSINESS_DETAIL =
  API_ENDPOINT + "msme/smartSearch/businessDetail";
export const GET_SMART_SEARCH_DEFAULTER_DETAIL =
  API_ENDPOINT + "msme/smartSearch/defaulterDetail";
export const GET_SMART_SEARCH_ADDITIONAL_CONTACT_LOG =
  API_ENDPOINT + "msme/smartSearch/additionalContactLog";
export const DOWNLOAD_DEFAULTER_CONTACT_EVIDENCE =
  API_ENDPOINT + "defaulter/download/contactEvidence";
export const DOWNLOAD_EVIDENCE_FILE = API_ENDPOINT + "download/evidence-file";
