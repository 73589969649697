import React from "react";
import { Table } from "react-bootstrap";
import { format } from "date-fns";
import smartSearchService from "../../../services/smartSearch";

export const BlockAdditionalContactLog = (props) => {
  const { contactList } = props;

  //Heading of the table
  const _tableHeader = () => {
    return (
      <thead>
        <tr>
          <th>S.No</th>
          <th>Old Detail</th>
          <th>New Detail</th>
          <th>Status</th>
          <th>Added by</th>
          <th>Date & Time</th>
          <th>Action</th>
        </tr>
      </thead>
    );
  };

  console.log("additionalContanctList@@@@@result", contactList);

  const _list = () => {
    if (contactList?.length < 1) {
      return (
        <tr>
          <td colSpan="6">No record found!!</td>
        </tr>
      );
    }
    return contactList.map((item, index) => {
      return (
        <tr key={item.id}>
          <td>{index + 1}</td>
          <td>{item?.old_contact ? item.old_contact : ""}</td>
          <td>{item?.new_contact ? item?.new_contact : ""}</td>
          <td>{item?.contact_status ? item?.contact_status : ""}</td>
          <td>
            {item.added_by_name}
            <br />
            {item.added_by_mobile}
          </td>
          <td>
            {item?.date_created
              ? format(new Date(item?.date_created), "dd-MM-yyyy h:mm:ss a")
              : ""}
          </td>
          <td>
            {item?.document_path ? (
              <span
                onClick={() =>
                  downloadContactEvidenceHandler(item?.document_path)
                }
                style={{ cursor: "pointer" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                  style={{ width: "22px", height: "auto" }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                  />
                </svg>{" "}
                Evidence
              </span>
            ) : (
              "N/A"
            )}
          </td>
        </tr>
      );
    });
  };

  const downloadContactEvidenceHandler = (filePath) => {
    let fileExtension = filePath.split(".").pop();
    let payload = {
      filename: `evidence.${fileExtension}`,
      filepath: filePath,
      fileName: `evidence.${fileExtension}`,
    };

    smartSearchService.downloadContactEvidence(payload);
  };

  return (
    <>
      <div>
        <Table striped bordered hover>
          {_tableHeader()}
          <tbody>{_list()}</tbody>
        </Table>
      </div>
    </>
  );
};
