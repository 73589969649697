import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
//import { setMessage } from "../message";
import smartSearchService from "../../../services/smartSearch";

export const getAdditionalContactLog = createAsyncThunk(
  "smartSearch/getAdditionalContactLog",
  async (payload, thunkAPI) => {
    try {
      console.log("smartSearch/getAdditionalContactLog@@@@payload", payload);
      const result = await smartSearchService.getAdditionalContactLog(payload);
      console.log("contantlog@result", result);
      if (result?.status) {
        return result;
      } else {
        // thunkAPI.dispatch(
        //   setMessage(result?.message ? result?.message : "An error occurred")
        // );
        toast.error(result?.message ? result?.message : "An error occurred");
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  smartSearchAdditionalContactLog: null,
};

const smartSearchAdditionalContactLogSlice = createSlice({
  name: "smartSearchAdditionalContactLog",
  initialState,
  extraReducers: {
    [getAdditionalContactLog.fulfilled]: (state, action) => {
      console.log(
        "smartSearchSlice - @@@@@@smartSearchAdditionalContactLog-fulfilled",
        action?.payload?.data
      );
      state.smartSearchAdditionalContactLog = action.payload.data;
      //return { ...state, defaulters: action.payload.data };
    },
    [getAdditionalContactLog.rejected]: (state, action) => {
      console.log(
        "smartSearchSlice - @@@@@@smartSearchAdditionalContactLog-rejected",
        action?.payload?.data
      );
      state.smartSearchBusinessDetail = null;
      //return { ...state, defaulters: [] };
    },
  },
});

export default smartSearchAdditionalContactLogSlice.reducer;
