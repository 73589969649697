import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../message";
import smartSearchService from "../../../services/smartSearch";

export const getDefaulterDetail = createAsyncThunk(
  "smartSearch/getDefaultDetail",
  async (payload, thunkAPI) => {
    try {
      console.log("smartSearch/getDefaultDetail@@@@payload", payload);
      const result = await smartSearchService.getDefaulterDetail(payload);
      if (result.status) {
        return result;
      } else {
        thunkAPI.dispatch(
          setMessage(result?.message ? result?.message : "An error occurred")
        );
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  smartSearchDefaulterDetail: null,
};

const smartSearchDefaulterDetailSlice = createSlice({
  name: "smartSearchDefaulter",
  initialState,
  extraReducers: {
    [getDefaulterDetail.fulfilled]: (state, action) => {
      console.log(
        "smartSearchSlice - @@@@@@getDefaulterDetail-fulfilled",
        action?.payload?.data
      );
      state.smartSearchDefaulterDetail = action.payload.data;
      //return { ...state, defaulters: action.payload.data };
    },
    [getDefaulterDetail.rejected]: (state, action) => {
      console.log(
        "smartSearchSlice - @@@@@@getDefaulterDetail-rejected",
        action?.payload?.data
      );
      state.smartSearchDefaulterDetail = null;
      //return { ...state, defaulters: [] };
    },
  },
});

export default smartSearchDefaulterDetailSlice.reducer;
