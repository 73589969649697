import * as Yup from "yup";
const gstPanRegex =
  /^(\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1})|([A-Z]{5}\d{4}[A-Z]{1})$/;
const mobileRegex = /^[6-9]\d{9}$/; // Validates 10-digit numbers starting with 6-9.

const validationSchema = Yup.object()
  .shape({
    mobile: Yup.string()
      .matches(mobileRegex, "Invalid mobile number")
      .nullable(), // Optional
    email: Yup.string().email("Invalid email address").nullable(), // Mark as optional
    gstPan: Yup.string()
      .matches(gstPanRegex, "Invalid GST or PAN number")
      .nullable(), // Mark as optional
    tradeName: Yup.string().min(3, "Must be at least 3 characters").nullable(), // Mark as optional
    legalName: Yup.string().min(3, "Must be at least 3 characters").nullable(), // Mark as optional
    userName: Yup.string().min(3, "Must be at least 3 characters").nullable(), // Mark as optional
    searchFor: Yup.string().nullable(), // Mark as optional
  })
  .test(
    "at-least-one-field",
    "At least one field must be provided",
    (values) => {
      const { mobile, email, gstPan, tradeName, legalName, userName } =
        values || {};
      return (
        !!email ||
        !!gstPan ||
        !!tradeName ||
        !!legalName ||
        !!userName ||
        !!mobile
      );
    }
  );
export default validationSchema;
