import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { Table } from "react-bootstrap";
import PaginationList from "../../../components/Pagination";

export const BlockUserList = (props) => {
  const { setPage, onPaginationClick } = props;
  const [loading, setLoading] = useState();
  const { smartSearch } = useSelector((state) => state.smartSearchSlice);
  const navigate = useNavigate();

  //Heading of the table
  const _tableHeader = () => {
    return (
      <thead>
        <tr>
          <th>S.No</th>
          <th>Name</th>
          <th>Mobile</th>
          <th>Email</th>
          <th>Status</th>
          <th>Created At</th>
          <th>Action</th>
        </tr>
      </thead>
    );
  };

  let paginationData = {};
  if (smartSearch?.users?.data) {
    paginationData = smartSearch?.users.pagination;
  }

  const detailPageHandler = (id) => {
    const currentTimeStamp = new Date().getTime();
    const encodedId = btoa(`${currentTimeStamp}-${id}`);
    navigate(`/smartSearch/userDetail/${encodedId}`);
    console.log("detailPageHandler@@@@@@", encodedId);
  };

  const _list = () => {
    if (!smartSearch?.users?.data) {
      return (
        <tr>
          <td colSpan="8">No record found!!</td>
        </tr>
      );
    }
    return smartSearch?.users.data.map((item, index) => {
      return (
        <tr key={item.id}>
          <td>{index + 1}</td>

          <td>{item.username}</td>
          <td>{item.mobile}</td>
          <td>{item.email}</td>
          <td>{item.user_status}</td>
          <td>{format(new Date(item?.date_created), "dd-MM-yyyy")}</td>
          <td>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6 cursor-pointer"
              style={{ width: "25px", height: "auto", cursor: "pointer" }}
              onClick={() => detailPageHandler(item.id)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
              />
            </svg>
          </td>
        </tr>
      );
    });
  };

  const paginationClickHandler = (pageNumber) => {
    console.log("paginationData@@@@@clicked", pageNumber);
    onPaginationClick(pageNumber);
  };
  console.log("paginationData@@@@@", paginationData);

  return (
    <>
      <div>
        <Table striped bordered hover>
          {_tableHeader()}
          <tbody>{_list()}</tbody>
        </Table>
      </div>

      {paginationData && paginationData.total > paginationData.limit && (
        <PaginationList
          paginationData={paginationData}
          setPage={setPage}
          modulePath="app"
          onPageClick={paginationClickHandler}
          setLoading={setLoading}
        />
      )}
    </>
  );
};
